<!--  eslint-disable vue/multi-word-component-names  -->
<template>
    <div>
        <CoreContainer>
            <BaseContainerContent
                :padding="{ top: 'none', bottom: 'large' }"
                width="narrow"
            >
                <div
                    class="sim-wysiwyg-text"
                    v-html="StaticPageResponse?.getItem()?.content"
                />
            </BaseContainerContent>
        </CoreContainer>

        <!--  NEWSLETTER SECTION  -->
        <SectionNewsletter />
    </div>
</template>

<script lang="ts" setup>
const { page } = useCurrentPage()

const { data: StaticPageResponse } = useStaticPagesApiService().forId(page.value?.model?.id).useGet()

</script>
