import { ApiReactiveService, type ApiServiceUseFetchOptions } from '@composable-api/service/api.reactive-service'
import type { ApiModel } from '@composable-api/api.model'
import { StaticPageModel } from '../models/static-page.model'

class StaticPagesApiReactiveService<M extends ApiModel> extends ApiReactiveService<M> {
    useGet<T extends boolean>(options?: ApiServiceUseFetchOptions<M, T>) {
        return this.useFetch({
            ...options,
            method: 'GET',
        })
    }
}

export default function useStaticPagesApiService() {
    return new StaticPagesApiReactiveService({
        endpoint: '/static-pages',
    }, StaticPageModel)
}
